import classNames from 'classnames';
import React, {FC, useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {ButtonComponent, Spinner} from '@figleafteam/components';
import Logo from '@renderer/images/logo.svg';
import {OnboardingProps} from './onboarding.types';
import BlockTrackers from './images/block-trackers.png';
import ConnectPrivately from './images/connect-privately.png';
import KnowWhereInfo from './images/know-where-info.png';
import LogIn from './images/log-in.png';
import KeepEmailPrivate from './images/keep-email-private.png';
const ONBOARDING_STEP = 'ONBOARDING_STEP';
import './_onboarding.scss';

const STEPS_CONFIG = [
  {
    title: 'Connect privately',
    description: (
      <Trans i18nKey="Use our VPN to hide your location and encrypt your online traffic while browsing the internet, protecting you from hackers and other online threats.">
        Use our VPN to hide your location and encrypt your online traffic while
        <br />
        browsing the internet, protecting you from hackers and other online threats.
      </Trans>
    ),
    image: ConnectPrivately,
  },
  {
    title: 'Know where your private info is',
    description: (
      <Trans i18nKey="We constantly match your identity profile against breached databases and data marketplaces. Once we find your exposed data, we’ll send you a personalized alert.">
        We constantly match your identity profile against breached databases and data
        <br />
        marketplaces. Once we find your exposed data, we’ll send you a personalized alert.
      </Trans>
    ),
    image: KnowWhereInfo,
  },
  {
    title: 'Log in anywhere in a snap',
    description: (
      <Trans i18nKey="Once you add accounts to your Vault, use FigLeaf extension to quickly and securely sign in to your accounts with a single click.">
        Once you add accounts to your Vault, use FigLeaf extension to
        <br />
        quickly and securely sign in to your accounts with a single click.
      </Trans>
    ),
    image: LogIn,
  },
  {
    title: 'Keep your email address private',
    description: (
      <Trans i18nKey="FigLeaf gives you a Private Email ID to use when signing up somewhere new.">
        FigLeaf gives you a Private Email ID to use when signing up
        <br />
        somewhere new.
      </Trans>
    ),
    image: KeepEmailPrivate,
  },
  {
    title: 'Block ad-trackers',
    description: (
      <Trans i18nKey="Eliminate advertising trackers from sites you visit. Customize anti-tracking features by site.">
        Eliminate advertising trackers from sites you visit. Customize
        <br />
        anti-tracking features by site.
      </Trans>
    ),
    image: BlockTrackers,
  },
];

export const OnboardingComponent: FC<OnboardingProps> = ({isLoading, children}) => {
  const {t} = useTranslation();
  const [currentStepIndex, _setCurrentStepIndex] = useState(Number(localStorage.getItem(ONBOARDING_STEP)));
  const lastStepIndex = STEPS_CONFIG.length - 1;
  const data = STEPS_CONFIG[currentStepIndex];
  const readyToShowApp = currentStepIndex > lastStepIndex && !isLoading;

  const setCurrentStepIndex = (index: number) => {
    localStorage.setItem(ONBOARDING_STEP, String(index));
    _setCurrentStepIndex(index);
  };

  return (
    <>
      {!readyToShowApp && (
        <div className="app-onboarding">
          <div className="app-onboarding__modal">
            {data ? (
              <>
                <div className="app-onboarding__image" style={{backgroundImage: `url(${data.image})`}} />
                <h1 className="app-onboarding__title">{data.title}</h1>
                <span className="app-onboarding__description">{data.description}</span>
                <div className="app-onboarding__button-wrapper">
                  <ButtonComponent
                    onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                    type={currentStepIndex === lastStepIndex ? 'primary-green' : 'empty-bordered'}
                    text={currentStepIndex === lastStepIndex ? t('Let’s start') : t('Next')}
                    fullWidth
                  />
                </div>
                <div className="app-onboarding__dots">
                  {STEPS_CONFIG.map((_, index) => (
                    <div
                      role="button"
                      key={index}
                      onClick={() => setCurrentStepIndex(index)}
                      className={classNames('app-onboarding__dot', {
                        'app-onboarding__dot--active': index === currentStepIndex,
                      })}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="app-onboarding__loading">
                <Logo className="app-onboarding__logo" />
                <div className="app-onboarding__loading-bottom">
                  <Spinner />
                  <span className="app-onboarding__loading-text">{t('Loading FigLeaf...')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {children}
    </>
  );
};
