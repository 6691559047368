import {ConnectorBase} from '@common/communication/ConnectorBase';

export class WebAssemblyConnector extends ConnectorBase {
  private worker: Worker | undefined;

  public sendMessage(message: string): void {
    if (this.state == 'connected') {
      this.worker?.postMessage(message);
    }
  }

  public connect(): void {
    this.state = 'connecting';
    this.worker = new Worker(`background-service.js`, {name: 'Figleaf Background Service'});
    this.worker.onmessage = (event: MessageEvent) => {
      this.onRequest(event.data);
    };
  }

  public disconnect(): void {
    this.worker?.terminate();
    this.worker = undefined;
    this.state = 'disconnected';
  }

  private onRequest(req: string) {
    this.state = 'connected';
    if (req.startsWith('{')) {
      this.messageHandler?.(req);
    } else {
      eval(req);
    }
  }
}
