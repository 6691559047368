import React, {FC, useEffect, useState, useCallback} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {
  ButtonComponent,
  Checkbox,
  DropDownComponent,
  InputPasswordComponent,
  IconComponent,
} from '@figleafteam/components';
import {history, HISTORY_STATE_FROM} from '@renderer/js/router/history';

import UnlockScreenImage from '@renderer/images/illustrations/unlock-image.svg';

import {UnlockPageProps} from './unlock-page.types';
import './_unlock-page.scss';

export const UnlockPageComponent: FC<UnlockPageProps> = props => {
  const {appInfo} = props;
  const {t} = useTranslation();

  const controlsClassnames = classNames(['auth__controls auth__controls--unlock'], {
    'auth__controls--web': ENABLED_FEATURES.Platform === 'web',
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!props.accountInfo.login) {
      props.getUserLogin();
    }
  }, []);

  useEffect(() => {
    if (appInfo && appInfo.isMigrationRequired) {
      props.setIsMigrationRequired(true);
      history.push('/login');
      return;
    }

    if (!appInfo || appInfo.lockStatus) {
      return;
    }

    history.push('/');
  }, [appInfo]);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    props.unlockUser();
  };

  const handleForgotPassword = () => {
    history.push({
      pathname: '/recovery',
      state: {from: HISTORY_STATE_FROM.UNLOCK},
    });
  };

  const handleOpenSupport = () => {
    props.openSupportLink();
  };

  const handleLogout = () => {
    props.logout();
  };

  const handleFieldChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = ev.target;
    props.setUnlockPassword(value);
  }, []);

  const dropdownItems = [
    {
      id: 'forgot-password',
      component: (
        <ButtonComponent
          beforeComponent={<IconComponent name="help" size={24} />}
          type="empty"
          text={t('Forgot password')}
          fullWidth
          textAlign="left"
          size="sm"
          modifier="dropdown"
          component="div"
          onClick={handleForgotPassword}
        />
      ),
    },
    {
      id: 'support',
      component: (
        <ButtonComponent
          beforeComponent={<IconComponent name="support" size={24} />}
          type="empty"
          text={t('Contact support')}
          fullWidth
          buttonType="button"
          textAlign="left"
          size="sm"
          modifier="dropdown"
          component="div"
          onClick={handleOpenSupport}
        />
      ),
    },
    {
      id: 'logout',
      component: (
        <ButtonComponent
          beforeComponent={<IconComponent name="logout" size={24} />}
          type="empty"
          text={t('Log out')}
          fullWidth
          textAlign="left"
          size="sm"
          modifier="dropdown"
          component="div"
          onClick={handleLogout}
        />
      ),
    },
  ];

  return (
    <div className="unlock-page">
      <div className="unlock-page__col">
        <div className="unlock-page__image">
          <UnlockScreenImage />
        </div>
      </div>
      <div className="unlock-page__col">
        <div className="unlock-page__content">
          <h1 className="unlock-page__title">{t('Unlock {{partner}}', {partner: 'FigLeaf'})}</h1>
          <form className="unlock-page__form" onSubmit={handleSubmit}>
            <div className="unlock-page__form-input">
              <InputPasswordComponent
                value={props.password}
                onChange={handleFieldChange}
                name="password"
                type="password"
                placeholder={t('Password')}
                autoFocus
              />
            </div>
            <div className={controlsClassnames}>
              {ENABLED_FEATURES.Platform !== 'web' && (
                <label className="auth__checkbox-wrapper">
                  <Checkbox
                    size="sm"
                    isChecked={props.savePassword}
                    onChange={() => props.setSavePasswordStatus(!props.savePassword)}
                  />
                  <span className="auth__checkbox-text">{t('Keep me logged in')}</span>
                </label>
              )}
              <div className="auth__dropdown">
                <ButtonComponent
                  afterComponent={<IconComponent name="triangle" size={24} />}
                  type="empty-radius"
                  text={props.accountInfo.login || t('your email')}
                  textAlign="left"
                  size="sm"
                  onClick={() => setIsDropdownOpen(true)}
                />
                {isDropdownOpen && (
                  <DropDownComponent
                    modifier="unlock"
                    closeDropdown={() => setIsDropdownOpen(false)}
                    items={dropdownItems}
                  />
                )}
              </div>
            </div>
            <div className="unlock-page__buttons">
              <ButtonComponent
                type="primary"
                buttonType="submit"
                fullWidth
                disabled={!props.password}
                text={t('Unlock')}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
