import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getIsLoading} from '@renderer/js/components/app/redux/selectors';
import {OnboardingComponent} from './onboarding.component';

const mapStateToProps = (state: AppState) => ({
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {};

export const OnboardingContainer = connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent);
