import {
  PPI_LOCAL_STORAGE_KEY,
  PPI_LOCAL_STORAGE_VERSION,
  PPI_LOCAL_STORAGE_VERSION_KEY,
  LOCAL_STORAGE_PPI_ACTIONS,
} from '@renderer/js/components/ppi-funnel/redux/constants';
import {AnyAction} from '@renderer/js/global-types/AnyAction';
import {AppState} from '@renderer/js/redux/rootReducer';

export const persistPpiStore =
  (store: {getState: () => AppState}) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
    const result = next(action);

    if (localStorage.getItem(PPI_LOCAL_STORAGE_VERSION_KEY) !== PPI_LOCAL_STORAGE_VERSION) {
      localStorage.setItem(PPI_LOCAL_STORAGE_VERSION_KEY, PPI_LOCAL_STORAGE_VERSION);
    }

    if (LOCAL_STORAGE_PPI_ACTIONS.has(action.type as any)) {
      const {ppi} = store.getState();
      localStorage.setItem(PPI_LOCAL_STORAGE_KEY, JSON.stringify(ppi));
    }

    return result;
  };
