import {LOGOUT_SUCCESS} from '@renderer/js/components/auth/redux/constants';
import {TLogOutSuccessAction} from '@renderer/js/components/auth/redux/types';
import {
  buildAccountHijackingCategory,
  buildHomeTitleTheftCategory,
  buildIdentityExposureCategory,
} from '@renderer/js/components/ppi-funnel/helpers/categories';
import {
  HIDE_RISK_ITEM,
  SET_PPI_FUNNEL_STATE,
  SET_RESOLVED_RISK,
  SET_RISK_CATEGORIES,
  PPI_LOCAL_STORAGE_VERSION_KEY,
  PPI_LOCAL_STORAGE_VERSION,
  PPI_LOCAL_STORAGE_KEY,
} from '../constants';
import {
  RiskTypes,
  THideRiskItemAction,
  TPpiFunnelState,
  TSetPpiFunnelStateAction,
  TSetResolvedRiskAction,
  TSetRiskCategoriesAction,
} from '../types';

const isVersionValid = localStorage.getItem(PPI_LOCAL_STORAGE_VERSION_KEY) === PPI_LOCAL_STORAGE_VERSION;
const storedFunnelState = isVersionValid ? localStorage.getItem(PPI_LOCAL_STORAGE_KEY) : null;

const initialFunnelState: TPpiFunnelState = {
  scannerStep: 'initial',
  isLoading: false,
  isInitialScanFinished: false,
  isDataFetched: false,
  isPpiFunnelShown: false,
  globalRiskType: RiskTypes.medium,
  exposedItemsCount: 0,
  exposedCategoriesCount: 0,
  isAllRisksResolved: false,
  isRisksScannerOpened: true,
  riskCategories: {
    identityExposure: buildIdentityExposureCategory({}),
    accountHijacking: buildAccountHijackingCategory({}),
    homeTitleTheft: buildHomeTitleTheftCategory({}),
  },
  decreaseRisks: {
    resolved: [],
    hidden: [],
  },
};

type TActionType =
  | TSetPpiFunnelStateAction
  | TSetRiskCategoriesAction
  | TSetResolvedRiskAction
  | THideRiskItemAction
  | TLogOutSuccessAction;

export const funnelStateReducer = (
  state: TPpiFunnelState = storedFunnelState ? JSON.parse(storedFunnelState).funnel : initialFunnelState,
  action: TActionType,
): TPpiFunnelState => {
  switch (action.type) {
    case SET_PPI_FUNNEL_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_RISK_CATEGORIES: {
      return {
        ...state,

        riskCategories: {
          ...state.riskCategories,
          ...action.payload,
        },
      };
    }

    case SET_RESOLVED_RISK: {
      return {
        ...state,
        decreaseRisks: {
          ...state.decreaseRisks,
          resolved: [...state.decreaseRisks.resolved, action.payload.id],
        },
      };
    }

    case HIDE_RISK_ITEM: {
      return {
        ...state,
        decreaseRisks: {
          ...state.decreaseRisks,
          hidden: [...state.decreaseRisks.hidden, action.payload.id],
        },
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...initialFunnelState,
      };
    }

    default: {
      return state;
    }
  }
};
