export const RECOVERY_KEY_MASK = [
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  '-',
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  '-',
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  '-',
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  '-',
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  '-',
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  '-',
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
  /([A-Z]|\d)/,
];
