export const EVENTS = {
  MAIN: {
    FIRST_LAUNCH: 'first_launch',
    LAUNCH: 'launch',
    UPDATE: 'update',
    CLOSE: 'app_close',
  },
  WEB: {
    FIRST_LAUNCH: 'web_first_launch',
    LAUNCH: 'web_launch',
  },
  WELCOME_SCREEN: {
    DISCOVER_EXPOSED_DATA_ITEM_CLICK: 'welcome_screen_discover_exposed_data_item_click',
    CONTROL_IDENTITY_ITEM_CLICK: 'welcome_screen_control_identify_item_click',
    SECURE_MASK_DATA_ITEM_CLICK: 'welcome_screen_secure_mask_data_item_click',
    LOGIN_SUBMIT: 'welcome_screen_login_submit',
    SIGNUP_SUBMIT: 'welcome_screen_signup_submit',
    LOGIN_ERROR: 'welcome_screen_login_error',
    SINGUP_ERROR: 'welcome_screen_signup_error',
    USER_LOGIN: 'user_login',
    USER_SIGNUP: 'user_signup',
  },
  NAVIGATION: {
    SUPPORT_BUTTON_CLICK: 'navigation_bar_support_button_click',
    BUYNOW_BUTTON_CLICK: 'navigation_bar_buy_now_click',
  },
  DASHBOARD: {
    DASHBOARD_SCREEN_SHOW: 'dashboard_screen_show',
    MONITOR_ADD_EMAIL_TO_MONITOR_BUTTON_CLICK: 'dashboard_screen_monitor_add_email_to_monitor_button_click',
    MONITOR_OPEN_MONITOR_BUTTON_CLICK: 'dashboard_screen_monitor_open_monitor_button_click',
    CONTROLS_INSTALL_EXTENSION_BUTTON_CLICK: 'dashboard_screen_controls_install_extension_button_click',
    CONTROLS_OPEN_CONTROLS_BUTTON_CLICK: 'dashboard_screen_controls_open_controls_button_click',
  },
  MONITOR: {
    MONITOR_SCREEN_SHOW: 'monitor_screen_show',
    DETAILS_BUTTON_CLICK: 'monitor_screen_details_button_click',
    DETAILS_BROWSER_EXTENSION_ITEM_CLICK: 'monitor_screen_details_browser_extension_item_click',
    DETAILS_SHOW_TUTORIAL_ITEM_CLICK: 'monitor_screen_details_show_tutorial_item_click',
    ONBOARDING_SHOW: 'monitor_screen_onboarding_show',
    ONBOARDING_CLOSE_BUTTON_CLICK: 'monitor_screen_onboarding_close_button_click',
    ONBOARDING_NEXT_BUTTON_CLICK: 'monitor_screen_onboarding_next_button_click',
    ONBOARDING_FINISH_BUTTON_CLICK: 'monitor_screen_onboarding_finish_button_click',
    MONITORED_DATA_BUTTON_CLICK: 'monitor_screen_monitored_data_button_click',
    ADD_EMAIL_TO_MONITOR_BUTTON_CLICK: 'monitor_screen_add_email_to_monitor_button_click',
    ADD_EMAIL_POPUP_SHOW: 'monitor_screen_add_email_popup_show',
    ADD_EMAIL_POPUP_SUBMIT: 'monitor_screen_add_email_popup_submit',
    CONFIRM_EMAIL_POPUP_SHOW: 'monitor_screen_confirm_email_popup_show',
    CONFIRM_EMAIL_POPUP_SUBMIT: 'monitor_screen_confirm_email_popup_submit',
    CONFIRM_EMAIL_SUCCESS: 'monitor_screen_confirm_email_success',
    CONFIRM_EMAIL_ERROR: 'monitor_screen_confirm_email_error',
    EMAILS_LIST_POPUP_SHOW: 'monitor_screen_emails_list_popup_show',
    EMAILS_LIST_POPUP_CLOSE: 'monitor_screen_emails_list_popup_close',
    EMAILS_LIST_POPUP_ADD_EMAIL_TO_MONITOR_BUTTON_CLICK:
      'monitor_screen_email_list_popup_add_emails_to_monitor_button_click',
    EMAILS_LIST_POPUP_REMOVE_EMAIL_BUTTON_CLICK: 'monitor_screen_emails_list_popup_remove_email_button_click',
    BREACH_ITEM_CLICK: 'monitor_screen_breach_item_click',
    DELETE_BREACH_CLICK: 'monitor_delete_breach_item_click',
    CONFIRM_DELETE_BREACH_CLICK: 'monitor_confirm_delete_breach_item_click',
  },
  CONTROLS: {
    CONTROLS_SCREEN_SHOW: 'controls_screen_show',
    DETAILS_BUTTON_CLICK: 'controls_screen_details_button_click',
    DETAILS_BROWSER_EXTENSION_ITEM_CLICK: 'controls_screen_details_browser_extension_item_click',
    DETAILS_SHOW_TUTORIAL_ITEM_CLICK: 'controls_screen_details_show_tutorial_item_click',
    ONBOARDING_SHOW: 'controls_screen_onboarding_show',
    ONBOARDING_CLOSE_BUTTON_CLICK: 'controls_screen_onboarding_close_button_click',
    ONBOARDING_NEXT_BUTTON_CLICK: 'controls_screen_onboarding_next_button_click',
    ONBOARDING_FINISH_BUTTON_CLICK: 'controls_screen_onboarding_finish_button_click',
    VPN_ENABLE: 'controls_screen_vpn_enable',
    VPN_DISABLE: 'controls_screen_vpn_disable',
    VPN_ERROR: 'controls_screen_vpn_error',
    VPN_CHOOSE_COUNTRY: 'controls_screen_vpn_choose_country',
    ANTITRACKING_ENABLE: 'controls_screen_anti_tracking_enable',
    ANTITRACKING_DISABLE: 'controls_screen_anti_tracking_disable',
    INSTALL_CHROME_EXTENSION_BUTTON_CLICK: 'controls_screen_install_chrome_extension_button_click',
    INSTALL_FIREFOX_EXTENSION_BUTTON_CLICK: 'controls_screen_install_firefox_extension_button_click',
    INSTALL_MICROSOFT_EDGE_EXTENSION_BUTTON_CLICK: 'controls_screen_install_microsoft_edge_extension_button_click',
  },
  VAULT: {
    VAULT_SCREEN_SHOW: 'vault_screen_show',
    DETAILS_BUTTON_CLICK: 'vault_screen_details_button_click',
    DETAILS_BROWSER_EXTENSION_ITEM_CLICK: 'vault_screen_details_browser_extension_item_click',
    DETAILS_SHOW_TUTORIAL_ITEM_CLICK: 'vault_screen_details_show_tutorial_item_click',
    ONBOARDING_SHOW: 'vault_screen_onboarding_show',
    ONBOARDING_CLOSE_BUTTON_CLICK: 'vault_screen_onboarding_close_button_click',
    ONBOARDING_NEXT_BUTTON_CLICK: 'vault_screen_onboarding_next_button_click',
    ONBOARDING_FINISH_BUTTON_CLICK: 'vault_screen_onboarding_finish_button_click',
    IMPORT_ACCOUNTS_SUCCESS: 'vault_screen_import_account_success',
  },
  SETTINGS: {
    SETTINGS_SCREEN_SHOW: 'settings_screen_show',
    LOGOUT_BUTTON_CLICK: 'settings_screen_logout_button_click',
    UPGRADE_BUTTON_CLICK: 'settings_screen_upgrade_click',
    SHOW_RECOVERY_KEY_BUTTON_CLICK: 'settings_screen_recovery_key_show_button_click',
    LOCK_BUTTON_CLICK: 'settings_screen_lock_button_click',
    DELETE_ACCOUNT_BUTTON_CLICK: 'settings_screen_delete_account_button_click',
    INSTALL_CHROME_EXTENSION_BUTTON_CLICK: 'settings_screen_install_chrome_extension_button_click',
    INSTALL_FIREFOX_EXTENSION_BUTTON_CLICK: 'settings_screen_install_firefox_extension_button_click',
    INSTALL_MICROSOFT_EDGE_EXTENSION_BUTTON_CLICK: 'settings_screen_install_microsoft_edge_extension_button_click',
    IMPORT_BUTTON_CLICK: 'settings_screen_import_button_click',
    NOT_SHARE_STATISTIC_BUTTON_CLICK: 'settings_screen_not_share_statistic_button_click',
    SHARE_STATISTIC_BUTTON_CLICK: 'settings_screen_share_statistic_button_click',
    UPDATE_APP_BUTTON_CLICK: 'settings_screen_update_button_click',
  },
  RECOVERY_KEY_POPUP: {
    SHOW: 'recovery_key_popup_show',
    CLOSE_BUTTON_CLICK: 'recovery_key_popup_close_button_click',
    SKIP_BUTTON_CLICK: 'recovery_key_popup_skip_button_click',
    SAVE_BUTTON_CLICK: 'recovery_key_popup_save_button_click',
  },
  ADD_BROWSER_EXTENSION_POPUP: {
    SHOW: 'add_browser_extension_popup_show',
    CLOSE: 'add_browser_extension_popup_close',
    INSTALL_CHROME_EXTENSION_BUTTON_CLICK: 'add_browser_extension_popup_install_chrome_extension_button_click',
    INSTALL_FIREFOX_EXTENSION_BUTTON_CLICK: 'add_browser_extension_popup_install_microsoft_edge_extension_button_click',
    INSTALL_MICROSOFT_EDGE_EXTENSION_BUTTON_CLICK: 'Add_browser_extension_popup_install_firefox_extension_button_click',
  },
  PAYWALL_POPUP: {
    SHOW: 'paywall_modal_show',
    CLOSE: 'paywall_modal_close',
    BUYNOW_BUTTON_CLICK: 'paywall_modal_buynow_button_click',
  },
  MONETIZATIOM: {
    OPEN_BUYNOW_URL: 'open_buynow_url',
  },
  NOTIFICATION: {
    UPDATE_APP_BUTTON_CLICK: 'notification_update_app_button_click',
  },
  PPI: {
    INITIAL_SCAN_START: 'ppi_initial_scan_start',
    INITIAL_SCAN_FINISHED: 'ppi_initial_scan_finished',
    INITIAL_SCAN_ERROR: 'ppi_initial_scan_error',

    RISK_SCANNER_OPEN: 'ppi_risk_scanner_open',
    RISK_SCANNER_HIDE: 'ppi_risk_scanner_hide',
    RISK_SCANNER_START_SCAN_OPEN: 'ppi_risk_scanner_start_scan_opened',
    RISK_SCANNER_PROGRESS_OPEN: 'ppi_risk_scanner_progress_opened',
    RISK_SCANNER_REPORT_OPEN: 'ppi_risk_scanner_report_opened',

    SELECT_RISK_REPORT_SELECT_IDENTITY_EXPOSURE: 'ppi_risk_report_select_identity_exposure',
    SELECT_RISK_REPORT_SELECT_HACKED_ACCOUNTS: 'ppi_risk_report_select_hacked_accounts',
    SELECT_RISK_REPORT_SELECT_EXPOSED_LOCATIONS: 'ppi_risk_report_select_exposed_locations',

    MONETIZATION_PAGE_OPEN: 'ppi_monetization_page_open',
    MONETIZATION_PAGE_OPEN_LOW_RISK: 'ppi_monetization_page_open_low_risk',
    MONETIZATION_PAGE_CLICK_ACTIVATE: 'ppi_monetization_page_click_activate',
    MONETIZATION_PAGE_CLICK_ACTIVATE_LOW_RISK: 'ppi_monetization_page_click_activate_low_risk',
    MONETIZATION_PAGE_CLICK_CONTINUE_TRIAL: 'ppi_monetization_page_click_continue_trial',
    DECREASE_RISK_PAGE_OPEN: 'ppi_decrease_risk_page_open',

    DECREASE_RISK_PAGE_VAULT_CLICK_RESOLVE: 'ppi_decrease_risk_page_vault_click_resolve',
    DECREASE_RISK_PAGE_VAULT_RESOLVE: 'ppi_decrease_risk_page_vault_resolve',
    DECREASE_RISK_PAGE_VAULT_SKIP: 'ppi_decrease_risk_page_vault_skip',

    DECREASE_RISK_PAGE_MONITOR_CLICK_RESOLVE: 'ppi_decrease_risk_page_monitor_click_resolve',
    DECREASE_RISK_PAGE_MONITOR_RESOLVE: 'ppi_decrease_risk_page_monitor_resolve',
    DECREASE_RISK_PAGE_MONITOR_SKIP: 'ppi_decrease_risk_page_monitor_skip',

    DECREASE_RISK_PAGE_TRACKERS_CLICK_RESOLVE: 'ppi_decrease_risk_page_trackers_click_resolve',
    DECREASE_RISK_PAGE_TRACKERS_RESOLVE: 'ppi_decrease_risk_page_trackers_resolve',
    DECREASE_RISK_PAGE_TRACKERS_SKIP: 'ppi_decrease_risk_page_trackers_skip',

    DECREASE_RISK_PAGE_VPN_CLICK_RESOLVE: 'ppi_decrease_risk_page_vpn_click_resolve',
    DECREASE_RISK_PAGE_VPN_RESOLVE: 'ppi_decrease_risk_page_vpn_resolve',
    DECREASE_RISK_PAGE_VPN_SKIP: 'ppi_decrease_risk_page_vpn_skip',

    DECREASE_RISK_PAGE_SHOW_FINAL_MODAL: 'ppi_decrease_risk_page_show_final_modal',
    DECREASE_RISK_PAGE_SHOW_VPN_MODAL: 'ppi_decrease_risk_page_show_vpn_modal',

    AUTH_OPEN_LOGIN_MODAL: 'ppi_auth_open_login_modal',
    AUTH_OPEN_SIGNUP_MODAL: 'ppi_auth_open_signup_modal',
    AUTH_OPEN_CONFIRM_DEVICE_MODAL: 'ppi_auth_open_confirm_device_modal',
    AUTH_OPEN_CONFIRM_ACCOUNT_MODAL: 'ppi_auth_open_confirm_account_modal',
  },
} as const;
