export const QUICK_SCAN_PROFILES = 'ppi/QUICK_SCAN_PROFILES' as const;
export const SET_RISK_CATEGORIES = 'ppi/SET_RISK_CATEGORIES' as const;
export const SET_PPI_FUNNEL_STATE = 'ppi/SET_PPI_FUNNEL_STATE' as const;
export const SET_RESOLVED_RISK = 'ppi/SET_RESOLVED_RISK' as const;
export const HIDE_RISK_ITEM = 'ppi/HIDE_RISK_ITEM' as const;
export const SET_PPI_FLOW_FINISHED = 'ppi/SET_PPI_FLOW_FINISHED' as const;
export const SET_PPI_LEAKS_STATE = 'ppi/SET_PPI_LEAKS_STATE' as const;
export const GET_VERIFIRED_EMAILS = 'ppi/GET_VERIFIRED_EMAILS' as const;
export const DELETE_EMAIL_FROM_REPORT = 'ppi/DELETE_EMAIL_FROM_REPORT' as const;
export const ADD_PPI_EMAIL_TO_MONITOR = 'ppi/ADD_PPI_EMAIL_TO_MONITOR' as const;
export const SEND_MONITOR_CONFIRMATION_CODE = 'ppi/SEND_MONITOR_CONFIRMATION_CODE' as const;
export const SUBMIT_MONITOR_CONFIRMATION_CODE = 'ppi/SUBMIT_MONITOR_CONFIRMATION_CODE' as const;
export const VERIFY_NEXT_EMAIL = 'ppi/VERIFY_NEXT_EMAIL' as const;
export const GET_FULL_REPORT = 'ppi/GET_FULL_REPORT' as const;

export const LOCAL_STORAGE_PPI_ACTIONS = new Set([
  SET_RISK_CATEGORIES,
  SET_PPI_FUNNEL_STATE,
  SET_RESOLVED_RISK,
  HIDE_RISK_ITEM,
  SET_PPI_LEAKS_STATE,
]);

export const PPI_LOCAL_STORAGE_KEY = 'ppi/store';
export const PPI_LOCAL_STORAGE_VERSION_KEY = 'ppi/version-key';
export const PPI_LOCAL_STORAGE_VERSION = '1';
