import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonComponent, FigleafGradientLogoComponent, ModalComponent} from '@figleafteam/components';
import {DownloadAppModalProps} from './download-app-modal.types';
import './_download-app-modal.scss';
import {WINDOWS_INSTALLER_FOR_PATCHING} from '../../redux/constants';

export const DownloadAppModalComponent = memo<DownloadAppModalProps>(({isOpen, setIsOpen}) => {
  const {t} = useTranslation();

  return (
    <>
      <ModalComponent isOpen={isOpen} handleClose={() => setIsOpen(false)} showCloseButton>
        <div className="download-app-modal">
          <FigleafGradientLogoComponent />
          <h1 className="download-app-modal__title">{t('Install the app to enable VPN')}</h1>
          <span className="download-app-modal__description">
            {t('Please download and install the FigLeaf application')}
          </span>
          <div className="download-app-modal__button-wrapper">
            <ButtonComponent
              type="primary"
              text={t('Download FigLeaf app')}
              fullWidth
              onClick={() => {
                window.open(WINDOWS_INSTALLER_FOR_PATCHING, '_blank');
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      </ModalComponent>
    </>
  );
});

DownloadAppModalComponent.displayName = 'DownloadAppModalComponent';
