export const GET_VPN_INFO = 'PrivateBrowsing/GET_VPN_INFO' as const;
export const SET_VPN_INFO = 'PrivateBrowsing/SET_VPN_INFO' as const;

export const GET_VPN_SESSION_START = 'PrivateBrowsing/GET_VPN_SESSION_START' as const;
export const SET_VPN_SESSION_START = 'PrivateBrowsing/SET_VPN_SESSION_START' as const;

export const GET_VPN_CONFIG = 'PrivateBrowsing/GET_VPN_CONFIG' as const;
export const SET_COUNTRY_LIST = 'PrivateBrowsing/SET_COUNTRY_LIST' as const;

export const SET_VPN_STATUS_IN_PROGRESS = 'PrivateBrowsing/SET_VPN_STATUS_IN_PROGRESS' as const;

export const SET_CURRENT_COUNTRY = 'PrivateBrowsing/SET_CURRENT_COUNTRY' as const;

export const DEFAULT_SETTINGS_CHANGED = 'PrivateBrowsing/DEFAULT_SETTINGS_CHANGED' as const;

export const REQUEST_ENABLE_VPN_CONNECTION = 'PrivateBrowsing/REQUEST_ENABLE_VPN_CONNECTION' as const;
export const REQUEST_DISABLE_VPN_CONNECTION = 'PrivateBrowsing/REQUEST_DISABLE_VPN_CONNECTION' as const;

export const SCHEDULE_VPN_CONNECTION = 'PrivateBrowsing/SCHEDULE_VPN_CONNECTION' as const;

export const SET_DEFAULT_SETTINGS = 'PrivateBrowsing/SET_DEFAULT_SETTINGS' as const;
export const GET_DEFAULT_SETTINGS = 'PrivateBrowsing/GET_DEFAULT_SETTINGS' as const;

export const GET_BROWSER_CONFIG = 'PrivateBrowsing/GET_BROWSER_CONFIG' as const;
export const SET_BROWSER_CONFIG = 'PrivateBrowsing/SET_BROWSER_CONFIG' as const;
export const UPDATE_BROWSER_CONFIG = 'PrivateBrowsing/UPDATE_BROWSER_CONFIG' as const;

export const GET_TRACKERS_BLOCKED_IN_BROWSER = 'PrivateBrowsing/GET_TRACKERS_BLOCKED_IN_BROWSER' as const;
export const SET_TRACKERS_BLOCKED_IN_BROWSER = 'PrivateBrowsing/SET_TRACKERS_BLOCKED_IN_BROWSER' as const;

export const GET_ANTITRACKING_CONFIG = 'PrivateBrowsing/GET_ANTITRACKING_CONFIG' as const;
export const SET_ANTITRACKING_CONFIG = 'PrivateBrowsing/SET_ANTITRACKING_CONFIG' as const;

export const UPDATE_DOMAIN_CONFIG = 'PrivateBrowsing/UPDATE_DOMAIN_CONFIG' as const;

export const CLEAR_ANTITRACKING_WHITELIST = 'PrivateBrowsing/CLEAR_ANTITRACKING_WHITELIST' as const;

export const VPN_SESSION_START = '[PrivateBrowsing]: VPN sessionStart' as const;

export const GET_ANTITRACKING_STATISTICS = 'PrivateBrowsing/GET_ANTITRACKING_STATISTICS' as const;
export const SET_ANTITRACKING_STATISTICS = 'PrivateBrowsing/SET_ANTITRACKING_STATISTICS' as const;

export const SET_IS_VPN_MODAL_OPENED = 'PrivateBrowsing/SET_IS_VPN_MODAL_OPENED' as const;

export const WINDOWS_INSTALLER_FOR_PATCHING =
  'https://api.figleaf.online/v1/utm?src=https://static.figleafapp.com/installers/win/figleaf-electron/FigLeaf.Installer.exe&utm_source=webapp&utm_medium=webapp&utm_campaign=webapp';
