import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {IconComponent} from '@figleafteam/components';

import './_side-bar.scss';
import './_side-bar-logo.scss';
import './_side-bar-list.scss';

import {LicenseInfoContainer} from '@renderer/js/components/monetization/components/monetization-info/monetization-info.container';
import {InternetStatusContainer} from '@renderer/js/components/side-bar/components/internet-status/internet-status.container';
import {AuthorizationStatusContainer} from '@renderer/js/components/side-bar/components/authorization-status/authorization-status.container';
import {EVENTS} from '@common/events';
import BasicLogo from './images-logo/path-file/basic-logo.svg';
import PremiumLogo from './images-logo/path-file/premium-logo.svg';
import {SideBarProps} from './side-bar.types';

export const SideBarComponent: FC<SideBarProps> = props => {
  const {isPremium} = props;
  const {t} = useTranslation();

  const isNeedToShowPpi = false; // !props.appInfo?.isAuthorized || !props.isPpiFlowFinished;

  return (
    <div className="side-bar">
      <div className="side-bar__menu">
        <div className="side-bar__vertical-col">
          <div className="side-bar-logo">
            <img src={isPremium ? PremiumLogo : BasicLogo} alt="Figleaf" />
          </div>

          <div className="side-bar-list">
            <ul className="side-bar-list__ul side-bar-list__ul--menu">
              <li className="side-bar-list__item">
                <NavLink
                  to="/dashboard"
                  activeClassName={'side-bar-list__link--active'}
                  className="side-bar-list__link"
                >
                  <span className="side-bar-list__link-icon">
                    <IconComponent name="home" size={24} />
                  </span>
                  <span className="side-bar-list__link-text">{t('Home')}</span>
                  {isNeedToShowPpi ? (
                    <span
                      className={`side-bar-list__risk-report-icon ${
                        props.riskScannerStep === 'report'
                          ? 'side-bar-list__risk-report-icon--attention'
                          : 'side-bar-list__risk-report-icon--question'
                      }`}
                    >
                      {
                        <IconComponent
                          name={props.riskScannerStep === 'report' ? 'attention-round' : 'help'}
                          size={24}
                        />
                      }
                    </span>
                  ) : null}
                </NavLink>
              </li>
              <li className="side-bar-list__item">
                <NavLink
                  to="/monitoring"
                  activeClassName={'side-bar-list__link--active'}
                  className="side-bar-list__link"
                >
                  <span className="side-bar-list__link-icon">
                    <IconComponent name="global-search" size={24} />
                  </span>
                  <span className="side-bar-list__link-text">{t('Monitor')}</span>
                </NavLink>
              </li>
              <li className="side-bar-list__item">
                <NavLink to="/browse" activeClassName={'side-bar-list__link--active'} className="side-bar-list__link">
                  <span className="side-bar-list__link-icon">
                    <IconComponent name="control" size={24} />
                  </span>
                  <span className="side-bar-list__link-text">{t('Controls')}</span>
                </NavLink>
              </li>
              <li className="side-bar-list__item">
                <NavLink to="/vault" activeClassName={'side-bar-list__link--active'} className="side-bar-list__link">
                  <span className="side-bar-list__link-icon">
                    <IconComponent name="lock" size={24} />
                  </span>
                  <span className="side-bar-list__link-text">{t('Vault')}</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <LicenseInfoContainer />
          <InternetStatusContainer />
          <AuthorizationStatusContainer />
        </div>
        <div className="side-bar__vertical-col">
          <div className="side-bar-list">
            <ul className="side-bar-list__ul">
              <li className="side-bar-list__item">
                <button
                  type="button"
                  className="side-bar-list__button"
                  onClick={() => {
                    props.sendTelemetryEvent({name: EVENTS.NAVIGATION.SUPPORT_BUTTON_CLICK, source: 'user'});
                    props.openSupportLink();
                  }}
                >
                  <span className="side-bar-list__link-icon">
                    <IconComponent name="help" size={24} />
                  </span>
                  <span className="side-bar-list__link-text">{t('Support')}</span>
                </button>
              </li>
              <li className="side-bar-list__item">
                <NavLink
                  onClick={event => {
                    if (!props.appInfo || !props.appInfo.isAuthorized) {
                      props.setAuthPopupOpened(true);
                      event.preventDefault();
                    }
                  }}
                  to="/settings"
                  activeClassName={'side-bar-list__link--active'}
                  className="side-bar-list__link"
                >
                  <span className="side-bar-list__link-icon">
                    <IconComponent name="settings" size={24} />
                  </span>
                  <span className="side-bar-list__link-text">{t('Settings')}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="side-bar__content">{props.children || ''}</div>
    </div>
  );
};
