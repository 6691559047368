import React, {memo, useEffect} from 'react';
import moment from 'moment';
import {useTranslation, Trans} from 'react-i18next';
import {ButtonComponent, IconComponent} from '@figleafteam/components';
import {TimeUnit} from '@common/utils/TimeUnit';
import {EVENTS} from '@common/events';

import {AccountSettingsProps} from './account-settings.types';

export const AccountSettingsComponent = memo<AccountSettingsProps>(props => {
  const {t} = useTranslation();

  useEffect(() => {
    if (!props.accountInfo.login) {
      props.getUserLogin();
    }

    if (!props.isLicenseExpired) {
      props.getLicenseInfoAction();
    }
  }, []);

  const handleLogout = () => {
    props.setConfirmModalState({
      title: t('Are you sure you want to log out?'),
      text: t('Make sure you remember your login and password'),
      isOpen: true,
      showCloseButton: true,
      isLoadingNeeded: true,
      loadingText: t('Logging out...'),
      actionButtonText: t('Log out'),
      buttonsWidth: '124',
      actionCallback: () => {
        props.sendTelemetryEvent({name: EVENTS.SETTINGS.LOGOUT_BUTTON_CLICK, source: 'user'});
        props.logout();
      },
    });
  };
  const handleOpenBuyNow = () => {
    props.sendTelemetryEvent({name: EVENTS.SETTINGS.UPGRADE_BUTTON_CLICK, source: 'user'});
    props.openBuyNow();
  };

  const handleLock = () => {
    props.sendTelemetryEvent({name: EVENTS.SETTINGS.LOCK_BUTTON_CLICK, source: 'user'});
    props.lockUser();
  };

  const showRecoveryKeyHintModal = () => {
    props.sendTelemetryEvent({name: EVENTS.SETTINGS.SHOW_RECOVERY_KEY_BUTTON_CLICK, source: 'user'});
    props.setRecoveryKeyModalState({
      isOpen: true,
    });
  };

  const handleDeleteFigleafAccount = () => {
    props.setConfirmModalState({
      title: (
        <Trans i18nKey="Are you sure you want to delete your FigLeaf account?">
          Are you sure you want to delete
          <br />
          your FigLeaf account?
        </Trans>
      ),
      text: t(
        'If you are sure you want to delete FigLeaf and all of its contents, please click “Delete {{partner}} Account” below and you’ll be redirected to our support form where you can submit your request',
        {partner: 'Figleaf'},
      ),
      isOpen: true,
      showCloseButton: true,
      actionButtonText: t('Request account deletion'),
      actionButtonType: 'warning',
      buttonsWidth: 'full',
      width: 520,
      actionCallback: () => {
        props.sendTelemetryEvent({name: EVENTS.SETTINGS.DELETE_ACCOUNT_BUTTON_CLICK, source: 'user'});
        props.openSupportLink('deleteAccount');
        props.setConfirmModalState({
          isOpen: false,
        });
      },
    });
  };

  const showEnterPromoCode = () => {
    props.changePromoCodeModalAction({
      isOpen: true,
      code: '',
    });
  };

  const paymentStatus =
    props.licenseInfo.type !== 'free' ? (
      <>
        <div className="settings-item">
          <div className="settings-item__content">
            <div className="settings-item__title-wrapper">
              <span className="settings-item__icon">
                <IconComponent name="document" size={24} />
              </span>
              <h3 className="settings-item__title">{t('Current plan')}</h3>
            </div>
            <div className="settings-item__data">
              <span className="settings-item__text">
                {!props.isLicenseExpired && (
                  <>
                    {t('Premium {{type}} • on {{date}}', {
                      date: moment(TimeUnit.seconds(props.licenseInfo.expirationDate || 0).milliseconds).format(
                        'MMM Do[,] YYYY',
                      ),
                      type: props.licenseInfo.type === 'trial' && 'Trial',
                    })}
                  </>
                )}
                {props.isLicenseExpired && props.licenseInfo.type === 'trial' && t('Basic plan. Premium Trial is out')}
                {props.isLicenseExpired && props.licenseInfo.type !== 'trial' && (
                  <>{t('Basic plan. Your subscription is up')}</>
                )}
              </span>
            </div>
          </div>
          <div className="settings-item__buttons">
            {props.isLicenseExpired && (
              <ButtonComponent type="primary" text={t('Subscribe')} onClick={handleOpenBuyNow} fullWidth />
            )}
            {!props.isLicenseExpired && props.licenseInfo.type === 'trial' && (
              <ButtonComponent type="primary" text={t('Subscribe')} onClick={handleOpenBuyNow} fullWidth />
            )}
            {(props.isLicenseExpired || props.licenseInfo.type === 'trial') && (
              <div className="settings-item__button-promocode">
                <ButtonComponent
                  type="link-light"
                  text={t('Enter promo code')}
                  onClick={showEnterPromoCode}
                  fullWidth
                />
              </div>
            )}
          </div>
        </div>
      </>
    ) : null;

  return (
    <section className="settings">
      <div className="settings-item">
        <div className="settings-item__content">
          <div className="settings-item__title-wrapper">
            <span className="settings-item__icon">
              <IconComponent name="user-round" size={24} />
            </span>
            <h3 className="settings-item__title">{t('Primary email')}</h3>
          </div>
          <div className="settings-item__data">
            <span className="settings-item__text">{props.accountInfo.login || ''}</span>
          </div>
        </div>
        <div className="settings-item__buttons">
          <ButtonComponent type="empty-bordered" fullWidth text={t('Log out')} onClick={handleLogout} />
        </div>
      </div>
      {paymentStatus}
      <div className="settings-item">
        <div className="settings-item__content">
          <div className="settings-item__title-wrapper">
            <span className="settings-item__icon">
              <IconComponent name="lock" size={24} />
            </span>
            <h3 className="settings-item__title">{t('Lock')}</h3>
          </div>
          <div className="settings-item__data">
            <span className="settings-item__text">
              {t('Lock FigLeaf to keep your info private when you’re not around your device')}
            </span>
          </div>
        </div>
        <div className="settings-item__buttons">
          <ButtonComponent type="empty-bordered" fullWidth text={t('Lock')} onClick={handleLock} />
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__content">
          <div className="settings-item__title-wrapper">
            <span className="settings-item__icon">
              <IconComponent name="key" size={24} />
            </span>
            <h3 className="settings-item__title">{t('Recovery key')}</h3>
          </div>
          <div className="settings-item__data">
            <span className="settings-item__text">{t('Make sure you keep your Recovery key somewhere safe')}</span>
          </div>
        </div>
        <div className="settings-item__buttons">
          <ButtonComponent type="empty-bordered" fullWidth text={t('Show')} onClick={showRecoveryKeyHintModal} />
        </div>
      </div>
      <div className="settings-item">
        <div className="settings-item__content">
          <div className="settings-item__title-wrapper">
            <span className="settings-item__icon">
              <IconComponent name="delete" size={24} />
            </span>

            <h3 className="settings-item__title">{t('Delete account')}</h3>
          </div>
          <div className="settings-item__data">
            <span className="settings-item__text">{t('Wipe your info from FigLeaf completely')}</span>
          </div>
        </div>
        <div className="settings-item__buttons">
          <ButtonComponent
            type="error-empty-bordered"
            fullWidth
            text={t('Delete')}
            onClick={handleDeleteFigleafAccount}
          />
        </div>
      </div>
    </section>
  );
});

AccountSettingsComponent.displayName = 'AccountSettingsComponent';
