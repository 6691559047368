import {connect} from 'react-redux';
import {AppState} from '@renderer/js/redux/rootReducer';
import {getUserProfileAction} from '@renderer/js/components/settings/redux/actions';
import {getAppInfo, getIsLoading} from '@renderer/js/components/app/redux/selectors';
import {getAppInfoAction} from '@renderer/js/components/app/redux/actions';
import {sendTelemetryEvent} from '@figleafteam/store';
import {AppComponent} from './app.component';

const mapStateToProps = (state: AppState) => ({
  appInfo: getAppInfo(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  getAppInfoAction,
  getUserProfileAction,
  sendTelemetryEvent,
};

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
