import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {HeadingComponent} from '@figleafteam/components';
import {VaultWidgetContainer} from '@figleafteam/vault-components';
import {VaultLocalesProvider} from '@figleafteam/vault-electron';
import {MonitorWidgetContainer, MonitorLocalesProvider} from '@figleafteam/monitor-electron';
import {TipsWidgetContainer} from '@renderer/js/components/dashboard/components/tips/tips-widget.container';
import ZeroKnowledge from '@renderer/images/zero-knowledge-current-color.svg';
import {MaskedCardWidgetContainer} from '@renderer/js/components/vault/components/masked-card-widget/masked-card-widget.container';
import {RisksControlContainer} from '@renderer/js/components/ppi-funnel/components/ppi-risks-control/risks-control.container';
import {BrowseWidgetContainer} from '@renderer/js/components/private-browsing/components/browse-widget/browse-widget.container';
import {openURL} from '@common/compatibility/openURL';
import {EVENTS} from '@common/events';
import {history} from '@renderer/js/router/history';
import {DashboardProps} from './dashboard.types';
import './_zero-knowledge.scss';
import './_dashboard.scss';

const ZK: FC = () => {
  const handleClick = () => {
    openURL('https://figleaf.com/knowledge-base/general/privacy-related/about-zero-knowledge/');
  };
  return (
    <div className="zero-knowledge" role="button" onClick={handleClick}>
      <ZeroKnowledge className="zero-knowledge__image" />
    </div>
  );
};

export const DashboardComponent: FC<DashboardProps> = props => {
  const isNeedToShowPpi = false; //!props.appInfo?.isAuthorized || !props.isPpiFlowFinished;
  const {t} = useTranslation();

  useEffect(() => {
    props.sendTelemetryEvent({name: EVENTS.DASHBOARD.DASHBOARD_SCREEN_SHOW, source: 'app'});
  }, []);
  return (
    <div className="dashboard">
      <HeadingComponent
        modifier="dashboard"
        title={t('Home')}
        controls={isNeedToShowPpi ? <RisksControlContainer /> : <ZK />}
      />
      <div className="dashboard__inner">
        <div className="dashboard__monitoring">
          <MonitorLocalesProvider>
            <MonitorWidgetContainer onClick={() => history.push('/monitoring')} />
          </MonitorLocalesProvider>
        </div>
        <div className="dashboard__vault">
          <VaultLocalesProvider>
            <VaultWidgetContainer
              openVault={() => {
                history.push('/vault');
              }}
              openVaultOnboarding={() => {
                history.push('/vault');
                props.setDetailsState({isOnboardingOpened: true});
              }}
            />
          </VaultLocalesProvider>
        </div>
        <div className="dashboard__browse">
          <BrowseWidgetContainer />
        </div>
        <div className="dashboard__tips">
          {props.maskedCardId && !props.isActiveMaskedCard && ENABLED_FEATURES.UiFeatures.CreditCardMasking ? (
            <MaskedCardWidgetContainer maskedCardId={props.maskedCardId} />
          ) : (
            <TipsWidgetContainer />
          )}
        </div>
      </div>
    </div>
  );
};
