import React, {useEffect, memo, useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import {
  ButtonComponent,
  FlagIconComponent,
  InputComponent,
  SelectComponent,
  SwitcherComponent,
  Spinner,
  IconComponent,
} from '@figleafteam/components';
import {i18n} from '@figleafteam/helpers';
import {EVENTS} from '@common/events';
import {useAuthorization} from '@renderer/js/components/auth/hooks/use-authorization';
import CountriesMap from './images/countries.svg';
import {LocationCircleComponent} from './location-circle/location-circle.component';
import {VpnProps} from './vpn.types';
import './_vpn.scss';
import {DownloadAppModalComponent} from '../download-app-modal/download-app-modal.component';

const getSessionDurationString = (sessionStart: string) => {
  const currentDate = new Date();
  const sessionStartDate = new Date(sessionStart);
  const difference = Number(currentDate) - Number(sessionStartDate);
  const duration = moment.duration(difference, 'milliseconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const durationComponents = [];
  if (days) {
    durationComponents.push(i18n.t('{{count}} day', {count: days}));
  }
  if (hours) {
    durationComponents.push(i18n.t('{{count}} hour', {count: hours}));
  }
  if (minutes) {
    if (hours || days) {
      durationComponents.push(i18n.t('{{count}} minute', {count: minutes}));
    } else {
      durationComponents.push(i18n.t('{{minutes}} min', {minutes}));
    }
  }
  if (seconds && !hours && !days) {
    durationComponents.push(i18n.t('{{seconds}} sec', {seconds}));
  }
  return durationComponents.join(' ');
};

export const VpnComponent = memo<VpnProps>(props => {
  const {
    isVpnEnabled,
    isVpnStatusInProgress,
    currentCountryCode,
    isVpnInfoFetched,
    isVpnConnectionScheduled,
    currentCountryName,
    isCountryListFetched,
    isLicenseExpired,
    countryList,
    currentCountryIp,
    vpnInProgressStatusText,
    vpnSessionStart,
    isModal,
    getVpnSessionStart,
    scheduleVpnConnection,
    getLicenseInfoAction,
    getVpnConfig,
    getVpnInfo,
    sendTelemetryEvent,
    setCurrentCountry,
    setPaywallModalState,
    enableVpn,
    disableVpn,
    setAuthPopupOpened,
  } = props;

  const [isDownloadModalOpened, setDownloadModalOpened] = useState(false);
  const {isAuthorized, runIfAuthorized} = useAuthorization();
  const [searchFilter, setSearchFilter] = useState('');
  const {t} = useTranslation();

  const [sessionDurationString, setSessionDurationString] = useState<string>();
  const [sessionDurationInterval, setSessionDurationInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (sessionDurationInterval) {
      clearInterval(sessionDurationInterval);
      setSessionDurationInterval(undefined);
    }

    if (vpnSessionStart) {
      const interval = setInterval(() => {
        setSessionDurationString(getSessionDurationString(vpnSessionStart));
      }, 1000);
      setSessionDurationInterval(interval);
    } else {
      setSessionDurationString(undefined);
    }
  }, [vpnSessionStart]);

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    if (!isVpnInfoFetched) {
      getVpnInfo();
    }
    if (!isLicenseExpired) {
      getLicenseInfoAction();
    }
    if (!vpnSessionStart) {
      getVpnSessionStart();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (!countryList.length && isAuthorized) {
      getVpnConfig();
    }
  }, [isVpnInfoFetched, isAuthorized]);

  useEffect(() => {
    if (!currentCountryCode) {
      return;
    }
    if (!isVpnStatusInProgress && isVpnConnectionScheduled) {
      sendTelemetryEvent({name: EVENTS.CONTROLS.VPN_ENABLE, source: 'user'});
      enableVpn();
    }
  }, [isVpnStatusInProgress, isVpnConnectionScheduled]);

  const selectCountry = (countryCode: string, countryName: string) => {
    sendTelemetryEvent({name: EVENTS.CONTROLS.VPN_CHOOSE_COUNTRY, source: 'user'});
    setCurrentCountry(countryCode, countryName);

    if (isLicenseExpired) {
      setPaywallModalState({isOpen: true});
      return;
    }

    if (isVpnEnabled) {
      disableVpn();
      scheduleVpnConnection();
      sendTelemetryEvent({name: EVENTS.CONTROLS.VPN_DISABLE, source: 'user'});
    } else {
      enableVpn();
      sendTelemetryEvent({name: EVENTS.CONTROLS.VPN_ENABLE, source: 'user'});
    }
  };

  const countryListItems = countryList
    ? countryList
        .filter(country => country.countryCode !== currentCountryCode)
        .filter(country => country.countryName.toLowerCase().startsWith(searchFilter.toLowerCase()))
        .map(country => ({
          id: country.countryCode,
          label: country.countryName,
          icon: (
            <div className="vpn__country-list-icon">
              <FlagIconComponent countryCode={country.countryCode} size="big" />
            </div>
          ),
        }))
    : null;

  const handleVpnChange = (isChecked: boolean) => {
    if (isChecked) {
      sendTelemetryEvent({name: EVENTS.CONTROLS.VPN_ENABLE, source: 'user'});
    } else {
      sendTelemetryEvent({name: EVENTS.CONTROLS.VPN_DISABLE, source: 'user'});
    }

    if (ENABLED_FEATURES.Platform === 'web') {
      setDownloadModalOpened(true);
      return;
    }

    if (isLicenseExpired) {
      setPaywallModalState({isOpen: true});
      return;
    }

    if (isChecked) {
      enableVpn();
    } else {
      disableVpn();
      sessionDurationInterval && clearInterval(sessionDurationInterval);
    }
  };

  const isVpnInited = isCountryListFetched && isVpnInfoFetched;

  let vpnStatusText = '';
  let isLoading = false;
  if (isVpnEnabled) {
    vpnStatusText = t('Connected');
  } else if (isAuthorized && (!isVpnInited || isVpnStatusInProgress)) {
    vpnStatusText = isVpnStatusInProgress ? vpnInProgressStatusText : t('Loading...');
    isLoading = true;
  } else {
    vpnStatusText = t('Disconnected');
  }

  let vpnInfo = null;

  if (!isLoading) {
    vpnInfo = isVpnEnabled ? (
      <div className="vpn__info">
        <div className="vpn__info-row">
          <span className="vpn__info-key">{t('Masked IP')}</span>
          <span className="vpn__info-value">
            <span className="vpn__ip-text">{currentCountryIp || ''}</span>
            <span className="vpn__ip-flag">
              <FlagIconComponent countryCode={currentCountryCode || ''} />
            </span>
          </span>
        </div>
        {sessionDurationString && (
          <div className="vpn__info-row">
            <span className="vpn__info-key">{t('Duration')}</span>
            <span className="vpn__info-value">{sessionDurationString}</span>
          </div>
        )}
      </div>
    ) : (
      <span className="vpn__disabled-text">{t('Enable VPN to mask your location and encrypt connection')}</span>
    );
  }

  return (
    <div
      className={classNames('vpn', {
        'vpn--modal': isModal,
      })}
    >
      <div>
        <div className="vpn__header">
          <h2 className="vpn__title">{t('VPN')}</h2>
        </div>

        <div className="vpn__controls">
          <div className="vpn__switcher">
            <SwitcherComponent
              disabled={!isAuthorized ? false : !isVpnInited || isVpnStatusInProgress}
              isChecked={isVpnEnabled}
              onChange={() => runIfAuthorized(() => handleVpnChange(!isVpnEnabled))}
            />
          </div>

          <div className="vpn__location">
            <div
              className="vpn__select"
              onClickCapture={e => {
                if (!isAuthorized) {
                  e.stopPropagation();
                  setAuthPopupOpened(true);
                }
              }}
            >
              <SelectComponent
                theme="vpn"
                disabled={!isAuthorized ? false : !countryList.length || isVpnStatusInProgress}
                items={countryListItems}
                selectedItem={currentCountryName || t('United States')}
                onSelect={selectCountry}
                searchComponent={
                  <InputComponent
                    value={searchFilter}
                    onChange={event => setSearchFilter(event.target.value)}
                    onBeforeUnmount={() => setSearchFilter('')}
                    type="text"
                    placeholder={t('Search...')}
                    name="search"
                    autoFocus
                  />
                }
              />
            </div>
            <div className="vpn__details">
              <div className="vpn__status-wrapper">
                <span
                  className={classNames('vpn__status-text', {
                    'vpn__status-text vpn__status-text--disconnected': !isVpnEnabled && !isVpnStatusInProgress,
                  })}
                >
                  {vpnStatusText}
                </span>
                {isLoading && <Spinner size={16} color="black" />}
              </div>
              {vpnInfo}
            </div>
            {isModal &&
              (isVpnEnabled ? (
                <span className="vpn__location-hidden">
                  <IconComponent name="check-round" size={24} /> {t('Location hidden')}
                </span>
              ) : (
                <div className="vpn__enable-button-wrapper">
                  <ButtonComponent
                    type="primary"
                    text={t('Enable VPN')}
                    fullWidth
                    onClick={() => runIfAuthorized(() => handleVpnChange(!isVpnEnabled))}
                    disabled={!isVpnInited || isVpnStatusInProgress}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="vpn__map">
        <CountriesMap />
        {isVpnEnabled && !isVpnStatusInProgress ? <LocationCircleComponent id={currentCountryCode} /> : null}
      </div>
      <DownloadAppModalComponent isOpen={isDownloadModalOpened} setIsOpen={setDownloadModalOpened} />
    </div>
  );
});

VpnComponent.displayName = 'VpnComponent';
