import {EVENTS} from '@common/events';
import React, {FC, useEffect} from 'react';
// import {Loader} from '@figleafteam/components';
import {AppProps} from './app.types';
const FIRST_LAUNCH_KEY = 'FIRST_LAUNCH';

export const AppComponent: FC<AppProps> = ({getAppInfoAction, getUserProfileAction, sendTelemetryEvent, children}) => {
  useEffect(() => {
    getAppInfoAction();
    getUserProfileAction();
    const isFirstLaunch = localStorage.getItem(FIRST_LAUNCH_KEY);
    if (!isFirstLaunch) {
      sendTelemetryEvent({name: EVENTS.WEB.FIRST_LAUNCH, source: 'app'});
      localStorage.setItem(FIRST_LAUNCH_KEY, 'false');
    } else {
      sendTelemetryEvent({name: EVENTS.WEB.LAUNCH, source: 'app'});
    }
  }, []);

  // return <Loader isLoading={isLoading}>{children} </Loader>;
  return <>{children}</>;
};
